import axios from "axios";
import {
  defaultHeadersWithoutToken,
  defaultResponses,
  defaultHeadersText,
} from "../config/config";
import {
  ERROR_GET_PAYLOAD,
  GET_ALL_DATA_MONITHOR,
  GET_COUNT_ALL_RECORDS,
  GET_ERROR_RESPONSE_RETRY,
  GET_ERROR_ALL_DATA_MONITHOR,
  GET_ERROR_RESPONSE_CHANGE_STATUS,
  GET_OBJECT_TYPES,
  GET_PAYLOAD,
  GET_RESPONSE_CHANGE_STATUS,
  GET_RESPONSE_RETRY,
  GET_TITLE,
  EXPORT_ALL_DATA,
  ERROR_EXPORT_ALL_DATA,
  GET_MANDANTES,
  GET_TIMELINE_DATA
} from "./types";

var ApiUrl = "";

export /**
 * Actualizar ApiUrl del servicio monithor
 */
const setUrlMonithorService = (URL) => {
  ApiUrl = URL;
};

export /**
 * Obtiene todos los registros
 *
 * @method GET
 */
const getAllRecords = (data) => async (dispatch) => {

  let serviceUrl = "";
  let config = defaultHeadersWithoutToken();
  switch (data.version) {
    case 2:
      serviceUrl = `/monitorGetAllRecords`;
      Object.assign(config.headers, {
        page: data.page,
        rowPerPage: data.rowPerPage,
        searchParam: data.find,
        Type: data.type.replace("&objectType=","").replace("&",""),
        startDate: data.startDate,
        endDate: data.endDate,
        mandante: data.mandante
      });
      break;
    default:
      serviceUrl = `/monit?page=${data.page}&rowPerPage=${data.rowPerPage}&find=${data.find}${data.type}&startDate=${data.startDate}&endDate=${data.endDate}&method=${data.method}`;
      break;
  }

  try {
    var response = await axios.get(
      ApiUrl + serviceUrl,
      config
    );

    let responseTransform;
    switch (data.version) {
      case 2:
        responseTransform = response.data.data.map((item) => {
          return {
            ...item
            //,ID: item.OBJECT_ID !== undefined ? item.OBJECT_ID : item.PAYLOAD,
          };
        });
        break;
      default:
        responseTransform = response.data.map((item) => {
          return {
            ...item,
            ID: item.OBJECT_ID !== undefined ? item.OBJECT_ID : item.PAYLOAD,
          };
        });
        break;
    }

    dispatch({
      type: GET_ALL_DATA_MONITHOR,
      payload: { data: responseTransform, state: true },
    });
  } catch (err) {
    console.error(err);
    const response = !!err.response
      ? err.response.data
      : defaultResponses.noResponseFromApi;
    dispatch({
      type: GET_ERROR_ALL_DATA_MONITHOR,
      payload: response,
    });
  }
};

export /**
 * Obtiene todos los registros
 *
 * @method GET
 */
const getTitleAction = () => async (dispatch) => {
  if(!ApiUrl)
    return;
  try {
    let myConfig = defaultHeadersWithoutToken();
    Object.assign(myConfig.headers);
    console.info(ApiUrl + "/monit_title");
    const response = await axios.get(
      ApiUrl + "/monit_title",
      myConfig
    );
    dispatch({
      type: GET_TITLE,
      payload:
        response.data[0] !== undefined
          ? response.data[0].title
          : "Monithor Default",
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: GET_TITLE,
      payload: "Monithor Default",
    });
  }
};

export /**
 * Obtiene todos los registros
 *
 * @method GET
 */
const getObjectTypeAction = (data) => async (dispatch) => {
  if(!ApiUrl)
    return;

  let serviceUrl = "";
  let config = defaultHeadersWithoutToken();
  switch (data.version) {
    case 2:
      serviceUrl = `/typeTask`;
      break;
    default:
      serviceUrl = `/monit_type`;
      break;
  }

  try {
    var response = await axios.get(
      ApiUrl + serviceUrl,
      config
    );

    let responseTransform;
    switch (data.version) {
      case 2:
        responseTransform = response.data.data.map((item) => {
          return {
            id: item.name,
            name: item.name,
            value: item.name,
          };
        });
        break;
      default:
        responseTransform = response.data.map((item) => {
          return {
            id: item.N,
            name: item.GLOBAL_TYPE ?? item.TASK_TYPE,
            value: item.TASK_TYPE,
            view: item.VIEW,
            method: item.METHOD
          };
        });
        break;
    }

    dispatch({
      type: GET_OBJECT_TYPES,
      payload: { data: responseTransform, state: true },
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: GET_OBJECT_TYPES,
      payload: { data: [], state: true },
    });
  }
};

export /**
 * Obtiene todos los registros
 *
 * @method GET
 */
const getCountAllRecordsAction = (data) => async (dispatch) => {

  let serviceUrl = "";
  let config = defaultHeadersWithoutToken();
  switch (data.version) {
    case 2:
      serviceUrl = `/monitorCountAllRecords`;
      Object.assign(config.headers, {
        searchParam: data.find,
        Type: data.type.replace("&objectType=",""),
        startDate: data.startDate,
        endDate: data.endDate,
        mandante: data.mandante
      });
      break;
    default:
      serviceUrl = `/monit_count?find=${data.find}${data.type}&startDate=${data.startDate}&endDate=${data.endDate}&method=${data.method}`;
      break;
  }
  
  try {
    // let myConfig = defaultHeadersWithoutToken();
    // Object.assign(myConfig.headers, {
    //   ...data,
    // });
    var response = await axios.get(
      ApiUrl + serviceUrl,
      config
    );

    let responseData = 0;
    switch (data.version) {
      case 2:
        responseData = response.data !== undefined ? response.data.cantidad : 0;
        break;
      default:
        responseData = response.data !== undefined ? response.data[0].total : 0;
        break;
    }

    dispatch({
      type: GET_COUNT_ALL_RECORDS,
      payload: responseData,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: GET_COUNT_ALL_RECORDS,
      payload: 0,
    });
  }
};

export /**
 * Exportar todos los registros
 *
 * @method POST
 */
const exportAllRecords = (data) => async (dispatch) => {

  let serviceUrl = `/monit_download`;
  let config = defaultHeadersWithoutToken();

  try {
    var response = await axios.post(
      ApiUrl + serviceUrl,
      {
        email: data.email,
        objectType: data.type.replace("&objectType=",""),
        objectView: data.view,
        find: data.find,
        startDate: data.startDate,
        endDate: data.endDate,
        mandante: data.mandante,
        method: data.method
      },
      config
    );
    if (response.status === 200) {
      dispatch({
          type: EXPORT_ALL_DATA,
          payload: response.data.result.information,
      });
     } else {
      dispatch({
          type: ERROR_EXPORT_ALL_DATA,
          payload: response.data.result.information,
      });
     }
  } catch (err) {
    const response = !!err.response
      ? err.response.data
      : defaultResponses.noResponseFromApi;
    dispatch({
      type: ERROR_EXPORT_ALL_DATA,
      payload: response,
    });
  }
};

export /**
 * Ejecuta un registro
 *
 * @method POST
 */
const executeRecord = (selfData, isPayload) => async (dispatch) => {
  try {
    var response = await axios.post(
      `${ApiUrl}/${selfData.version===1? "monit_execute": "monitorexecuteRecord"}`,
      selfData,
      defaultHeadersWithoutToken()
    );
    if (response.status === 200) {
      dispatch({
          type: isPayload ? GET_PAYLOAD : GET_RESPONSE_RETRY,
          payload: selfData.version===1? response.data.result.information : "Acción realizada correctamente",
      });
     } else {
      dispatch({
          type: GET_ERROR_RESPONSE_RETRY,
          payload: selfData.version===1? response.data.result.information : "Error al realizar la acción",
      });
     }
  } catch (err) {
    console.error(err);
    const response = !!err.response
      ? err.response.data
      : defaultResponses.noResponseFromApi;
    dispatch({
      type: isPayload ? ERROR_GET_PAYLOAD : GET_ERROR_RESPONSE_RETRY,
      payload: response,
    });
  }
};

export /**
 * Ejecuta un registro
 *
 * @method POST
 * @param {history} history
 */
const updateStatus = (data) => async (dispatch) => {
  try {
    var response = await axios.post(
      ApiUrl + "/monitorUpdateStatusRecord",
      data,
      defaultHeadersWithoutToken()
    );
    if (response.status === 200) {
      dispatch({
          type: GET_RESPONSE_CHANGE_STATUS,
          payload: "Acción realizada correctamente",
      });
     } else {
      dispatch({
          type: GET_ERROR_RESPONSE_CHANGE_STATUS,
          payload: "Error al realizar la acción",
      });
     }
  } catch (err) {
    console.error(err);
    const response = !!err.response
      ? err.response.data
      : defaultResponses.noResponseFromApi;
    dispatch({
      type: GET_ERROR_RESPONSE_CHANGE_STATUS,
      payload: response,
    });
  }
};

export const getTimeLineData = async (transactionId, objType, method) => {
  try {
    let serviceUrl = ApiUrl + "/monit_sequence_details/" + transactionId;

    const queryParams = [
      objType ? `objectType=${encodeURIComponent(objType)+(method?`&method=${method}`:"")}` : (method?`method=${method}`:"")
    ]
    .filter(param => param !== null)
    .join('&');

    if (queryParams) {
      serviceUrl += `?${queryParams}`;
    }

    const response = await axios.get(serviceUrl);
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export const getDashboardData = async (startDate, endDate) => {
  try {
    let serviceUrl = ApiUrl + "/monit_dashboard";
    const queryParams = [
      startDate ? `startDate=${encodeURIComponent(startDate)}` : null,
      endDate ? `endDate=${encodeURIComponent(endDate)}` : null
    ]
    .filter(param => param !== null)
    .join('&');

    if (queryParams) {
      serviceUrl += `?${queryParams}`;
    }

    const response = await axios.get(serviceUrl);
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export const getDashboardResponseTimesData = async (objType, startDate, endDate) => {
  try {
    let serviceUrl = ApiUrl + "/dashboard_response_times";
    const queryParams = [
      objType ? `objectType=${encodeURIComponent(objType)}` : null,
      startDate ? `startDate=${encodeURIComponent(startDate)}` : null,
      endDate ? `endDate=${encodeURIComponent(endDate)}` : null
    ]
    .filter(param => param !== null)
    .join('&');

    if (queryParams) {
      serviceUrl += `?${queryParams}`;
    }

    const response = await axios.get(serviceUrl);
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export const getDashboardDataWithType = async (startDate, endDate, type, month) => {
  try {
    let serviceUrl = ApiUrl + "/monit_object_type";
    const queryParams = [
      startDate ? `startDate=${encodeURIComponent(startDate)}` : null,
      endDate ? `endDate=${encodeURIComponent(endDate)}` : null,
      type ? `type=${encodeURIComponent(type)}` : null,
      month ? `month=${encodeURIComponent(month)}` : null
    ]
    .filter(param => param !== null)
    .join('&');

    if (queryParams) {
      serviceUrl += `?${queryParams}`;
    }

    const response = await axios.get(serviceUrl);
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export const getDashboardMonthData = async (startDate, endDate, type, eventCode, month) => {
  try {
    let serviceUrl = ApiUrl + "/monit_dashboard_month";
    const queryParams = [
      startDate ? `startDate=${encodeURIComponent(startDate)}` : null,
      endDate ? `endDate=${encodeURIComponent(endDate)}` : null,
      type ? `type=${encodeURIComponent(type)}` : null,
      eventCode ? `eventCode=${encodeURIComponent(eventCode)}` : null,
      month ? `month=${encodeURIComponent(month)}` : null
    ]
    .filter(param => param !== null)
    .join('&');

    if (queryParams) {
      serviceUrl += `?${queryParams}`;
    }

    const response = await axios.get(serviceUrl);
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export const getDashboardColumnsData = async () => {
  try {
    let serviceUrl = ApiUrl + "/dashboard_columns";
    const response = await axios.get(serviceUrl);
    //console.log(serviceUrl)
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export const getDashboardObjectStatus = async (month) => {
  try {
    let serviceUrl = ApiUrl + "/dashboard_object_status";

    const queryParams = [
      month ? `startDate=${encodeURIComponent(month)}` : null
    ]
    .filter(param => param !== null)
    .join('&');

    if (queryParams) {
      serviceUrl += `?${queryParams}`;
    }

    const response = await axios.get(serviceUrl);
    console.log(serviceUrl);
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

export /**
 * Obtiene todos los Mandantes
 *
 * @method GET
 */
const getMandanteAction = () => async (dispatch) => {
  if(!ApiUrl)
    return;

  let serviceUrl = "/mandante";
  let config = defaultHeadersWithoutToken();

  try {
    var response = await axios.get(
      ApiUrl + serviceUrl,
      config
    );

    let responseTransform = response.data.data.map((item) => {
      return {
        id: item.name,
        name: item.name,
      };
    });

    dispatch({
      type: GET_MANDANTES,
      payload: { data: responseTransform, state: true },
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: GET_MANDANTES,
      payload: { data: [], state: true },
    });
  }
};