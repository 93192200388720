import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Home from "../views/dashboard/Home";
import Users from "../views/users/users.view";
import Login from "../views/auth/Login";

import ResetPassword from "../views/auth/resetPassword.view";
import ChangePassword from "../views/auth/changePassword.view";
import Privileges from "../views/privileges/privileges.view";
import Roles from "../views/roles/roles.view";
import Applications from "../views/applications/Applications.view";

import Monithor from "../views/monithor/monitoring.view";
import MonithorPPL from "../views/monithor/monitoringPpl.view";
import Integrations from "../views/integrations/integrations.view";
import Enterprises from "../views/enterprises/enterprises.view";
import Tracing from "../views/tracing/tracing.view";
import ObjectTypes from "../views/objectTypes/objectTypes.view";
import DataTypes from "../views/dataTypes/dataTypes.view";
import IntegrationTypes from "../views/integrationTypes/integrationTypes.view";
import Services from "../views/services/services.view";
import ResponseCodes from "../views/responseCodes/responseCodes.view";
import Homologations from "../views/homologations/homologations.view";
import CustomViews from "../views/customViews/customViews.view";
import Signatures from "../views/sign/signatures.view";
import CustomForms from "../views/customForms/customForms.view";
import { CustomViewsSuppliers } from "../views/customViews/customViewsSuppliers";

import ResetOtp from "../views/Otp/resetOtp.view";

import Alert from "../views/Alert";
import Logout from "../views/auth/logout.view";
import PrivateRoute from "./PrivateRoute";
import { validateTokenAction, tokenValidation } from "../actions/auth.action";
import { parseJwt } from "../utils/userInfo";
import { PublicRoute } from "./PublicRoute";
import MenuSettings from "../views/menuSettings/menusettings.view";
import { AvailableApps } from "../views/auth/availableapps.view";
import { ResendEmailView } from "../views/auth/resendEmail.view";
import { NotificationView } from "../views/notifications/notification.view";
import { ContainerClient } from "../views/clients/ContainerClient";
import { CustomViewsMaterials } from "../views/customViews/customViewsMaterials";
import { CustomViewsCalculator } from "../views/customViews/customViewsCalculator";

let history = createBrowserHistory();

/**
 * Browser Router (export a browser router with respective routes,
 * PrivateRoute middleware params(param1: Component, param2: 'accepted roles separated with colon eg. "Admin, Guest"')
 * can you send "" in param2 for accept all loggedIn users)
 *
 * @export class
 * @class Routes
 * @extends {React.Component}
 */

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuth: false,
      publicRoutes: [
        "/RESETPASSWORD",
        "/CHANGEPASSWORD",
        "/RESETOTP",
        "/ALERT",
        "/AVAILABLEAPPS",
        "/RESENDEMAIL",
        "/EMBEDDED/MONITHOR",
        "/EMBEDDED/CUSTOMVIEWS/CMI/SUPPLIERS",
        "/EMBEDDED/CUSTOMVIEWS/CMI/MATERIALS",
        "/EMBEDDED/CUSTOMVIEWS/CMI/CALCULATOR",
      ],
    };

    if (
      !this.state.publicRoutes.includes(history.location.pathname.toUpperCase())
    ) {
      const token = localStorage.getItem("jwtToken");
      if (token !== undefined && token !== null) {
        const { unique_name, APPS } = parseJwt(token);
        const betterToken = token.split("Bearer")[1].trim();
        this.props.tokenValidation(unique_name, betterToken, APPS, history, 1);
      } else {
        const encryptedToken = window.location.search.substring(1).split("&")[0];
        const token = localStorage.getItem("jwtToken");
        if ((encryptedToken === "" || encryptedToken.split("=").length >1) && token === null) {
          if(!window.location.pathname.toUpperCase().includes("LOGIN")){
            localStorage.setItem("lastUrl", window.location.pathname);
          }
          let path = "/Login";
          let search_ = "";

          //Redirect to public path if aplly
          if(window.location.pathname.includes("embedded") && (new URLSearchParams(window.location.search)).get("rd") == "p"){
            const url = document.referrer;
            const domainRegex = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/;
            const domain = url.length ? url.match(domainRegex) : [""];
  
            if(domain.length && 
                window.location.pathname.includes("private") && 
                this.state.publicRoutes.includes(window.location.pathname.replace("private","").toUpperCase()) &&
                (domain[0].includes("parameta.co") ||
                domain[0].includes("coupahost.com"))){
                  path = window.location.pathname.replace("private","");
                  search_ = window.location.search;
            }
          }

          history.push({
            pathname: path,
            search: search_
          });
        }
      }
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Router history={history}>
          <Switch>
            <PublicRoute
              path="/resetpassword"
              exact
              component={(props) => (
                <ResetPassword
                  {...this.props}
                  history={history}
                  isAuthed={true}
                />
              )}
            ></PublicRoute>
            <PublicRoute
              path="/changepassword"
              exact
              component={(props) => (
                <ChangePassword
                  {...this.props}
                  history={history}
                  isAuthed={true}
                />
              )}
            ></PublicRoute>
            <PublicRoute
              path="/login"
              exact
              isAuth={this.state.isAuth}
              // component={Login}
              component={(props) => (
                <Login
                  {...this.props}
                  history={history}
                  isAuthed={this.state.isAuth}
                />
              )}
            />
            <PublicRoute
              path="/"
              exact
              isAuth={this.state.isAuth}
              // component={Login}
              component={(props) => (
                <Login
                  {...this.props}
                  history={history}
                  isAuthed={this.state.isAuth}
                />
              )}
            />
            <PublicRoute
              path="/resetotp"
              exact
              component={(props) => (
                <ResetOtp {...this.props} history={history} isAuthed={true} />
              )}
            ></PublicRoute>
            <PublicRoute
              path="/embedded/monithor"
              exact
              component={(props) => (
                <div style={{ marginLeft: "10px" }}>
                  <Monithor {...this.props} history={history} isAuthed={true}/>
                </div>
              )}
              isEmbedded={true}
            />
            <PublicRoute
              path="/embedded/customviews/cmi/suppliers"
              exact
              component={(props) => (
                <div style={{ marginLeft: "10px" }}>
                  <CustomViewsSuppliers {...this.props} history={history} isAuthed={true}/>
                </div>
              )}
              isEmbedded={true}
            />
            <PublicRoute
              path="/embedded/customviews/cmi/materials"
              exact
              component={(props) => (
                <div style={{ marginLeft: "10px" }}>
                  <CustomViewsMaterials {...this.props} history={history} isAuthed={true}/>
                </div>
              )}
              isEmbedded={true}
            />
            <PublicRoute
              path="/embedded/customviews/cmi/calculator"
              exact
              component={(props) => (
                <div style={{ marginLeft: "10px" }}>
                  <CustomViewsCalculator {...this.props} history={history} isAuthed={true}/>
                </div>
              )}
              isEmbedded={true}
            />

            <PrivateRoute
              path="/embedded/monithorprivate"
              exact
              component={Monithor}
              history={history}
              {...this.props}
              isEmbedded={true}
            />
            <PrivateRoute
              path="/embedded/customviews/cmi/suppliersprivate"
              exact
              component={CustomViewsSuppliers}
              history={history}
              {...this.props}
              isEmbedded={true}
            />
            <PrivateRoute
              path="/embedded/customviews/cmi/materialsprivate"
              exact
              component={CustomViewsMaterials}
              history={history}
              {...this.props}
              isEmbedded={true}
            />
            <PrivateRoute
              path="/embedded/customviews/cmi/calculatorprivate"
              exact
              component={CustomViewsCalculator}
              history={history}
              {...this.props}
              isEmbedded={true}
            />

            <PublicRoute path="/logout" exact component={Logout}></PublicRoute>
            <PublicRoute path="/alert" exact component={Alert}></PublicRoute>
            <PublicRoute
              path="/availableapps"
              exact
              component={AvailableApps}
            ></PublicRoute>
            <PublicRoute
              path="/resendemail"
              exact
              component={ResendEmailView}
            ></PublicRoute>
            <PrivateRoute
              path="/"
              exact
              component={Home}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/home"
              exact
              component={Home}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/users"
              exact
              component={Users}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/privileges"
              exact
              component={Privileges}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/roles"
              exact
              component={Roles}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/apps"
              exact
              component={Applications}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/menuSettings"
              exact
              component={MenuSettings}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/notifications"
              exact
              component={NotificationView}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/clients"
              exact
              component={ContainerClient}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/monithor"
              exact
              component={MonithorPPL}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/integrations"
              exact
              component={Integrations}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/enterprises"
              exact
              component={Enterprises}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/tracing"
              exact
              component={Tracing}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/objecttypes"
              exact
              component={ObjectTypes}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/datatypes"
              exact
              component={DataTypes}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/integrationtypes"
              exact
              component={IntegrationTypes}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/services"
              exact
              component={Services}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/responsecodes"
              exact
              component={ResponseCodes}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/homologations"
              exact
              component={Homologations}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customviews"
              exact
              component={CustomViews}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/signatures"
              exact
              component={Signatures}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/exceptionmotivos"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/bancohomologation"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/bodegaconsumo"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/bodegatipociudad"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/ciiucodeshomologation"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/ciudadhomologation"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/connector"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/countryhomologation"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/datacoupaobject"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/datalote"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/eventlog"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/grupoinventariomotivo"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/itemcompanyvalue"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/paymenthomologation"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/planesitem"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/reteIcahomologation"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/suppliertypehomologation"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/tipocuentahomologation"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/tipodocumentohomologation"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/tipoproveedorhomologation"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/tipotransaccionhomologation"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/valoresretencione"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/inventoryupdateerror"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifeventlogmotivos"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexceptionmotivo"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifgrupoinventariomotivo"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/inventoryreferences"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/inventorytransactioncoupa"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/movimientosinventario"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifconfiguration"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifeventlog"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifeventlist"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifschedulledtask"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/piftypeinvoke"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifcrontimelog"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifsequencecounter"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstate"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/piftasktype"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedmetadata"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifmetadatarule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifitemrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstatemetadata"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedsupplier"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifsupplierrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstatesupplier"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedinvoice"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifinvoicerule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstateinvoice"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedrequisition"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifrequisitionrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstaterequisition"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedpurchaseorder"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifpurchaseorderrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstatepurchaseorder"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifaccount"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifdatainvoice"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifdataprovek"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifdatasupplier"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifdatatype"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifhomologation"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/itemhomologationsap"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifsupplierformresponse"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifralationsupplier"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedinvoicexml"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedpaymentnotification"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifinvoicexmlrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifpaymentnotificationrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifpowspackage"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstateinvoicexml"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstatepaymentnotification"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedit"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexporteditgroup"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedtaxvalidator"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifitgrouprule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifitrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifnotification"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifnotificationconfig"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifremoteapproverconfig"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifretrymanagement"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstateit"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstateitgroup"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstatetaxvalidator"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/piftaxvalidatorrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />

            <PrivateRoute
              path="/customforms/pifstateattachments"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedattachments"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifattachmentsrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstateexpense"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedexpense"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexpenserule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstateexpensesreadfile"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedexpensesreadfile"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexpensesreadfilerule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstateitem"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexporteditem"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifitemrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifstatecontract"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifexportedcontract"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/pifcontractrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />

            <PrivateRoute
              path="/customforms/*/pifattachment"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifconfiguration"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifeventlog"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifinvoicematching"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifinvoicepackage"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifinvoicepackagemail"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifInvoicerule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifschedulledtask"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifcrontimelog"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifdatatype"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifexportedmatchref"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifexportedtaxvalidator"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifinvoice"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifinvoicematchingref"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifmatchrefaction"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifmatchrefactionbysupplier"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifmatchrefrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifmatchreftolerance"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifpurchaseorder"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifpurchaseorderline"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifreceiptpurchaseorderline"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifstatematchref"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/piftaxvalidatorrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifstatetaxvalidator"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifstateinvoicexml"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
			      <PrivateRoute
              path="/customforms/*/pifinvoicexmlrule"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
			      <PrivateRoute
              path="/customforms/*/pifstateinvoicexml"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/purchaseorder"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/purchaseorderline"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/receiptpurchaseorderline"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifnotification"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
            <PrivateRoute
              path="/customforms/*/pifnotificationconfig"
              exact
              component={CustomForms}
              history={history}
              {...this.props}
            />
          </Switch>
        </Router>
      </BrowserRouter>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuth: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps, {
  validateTokenAction,
  tokenValidation,
})(Routes);
