import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import IconEspecialButton from "../../components/ButtonForm/IconEspecialButton.component";
import { Search } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  getDashboardData,
  getDashboardResponseTimesData,
  setUrlMonithorService,
} from "../../actions/monithor.action";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  plugins,
} from "chart.js";
import zoomPlugin from 'chartjs-plugin-zoom';
import { Doughnut, Bar, HorizontalBar } from "react-chartjs-2";
import { Scatter } from "react-chartjs-2";
import { map } from "lodash";
import { FullLoader } from "@pif-tr-components/components";

// ChartJS.plugins.register({
//   id: "responsetimes",
//   ArcElement,
//   Tooltip,
//   Legend,
//   plugins,
// });

export function MonitoringDashBoardResponseTimes({
  ...props
}) {
  const { Loading, user, onlyContent } = props;
  let [isLoading, setIsLoading] = useState(false);

  let params = new URLSearchParams(window.location.search);
  const [environmentURL, setEnvironmentURL] = useState(
    params.get("env") ? `https://${params.get("env")}.parameta.co` : ""
  );
  const [version, setVersion] = useState(parseInt(params.get("ver") ?? "1"));
  const [fullURL] = useState(
    params.get("fullurl") ? params.get("fullurl") : ""
  );

  const { getEnterpriseByIDResponse } = useSelector(
    (store) => store.enterpriseReducer
  );
  const [isData, setIsData] = useState(false);
  const [isResponseTimeData, setIsResponseTimeData] = useState(false);
  const chartRefResp = useRef(null);
  const chartRefTrans = useRef(null);
  const [dashboardData, setDashboardData] = useState({
    labels: [],
    datasets: [{ data: [], backgroundColor: [] }],
  });
  const [originalDashboardData, setOriginalDashboardData] = useState(null);
  const [objectType, setObjectType] = useState({objectType:"",BG:"#" + Math.floor(Math.random() * 16777215).toString(16)});
  const [transactionChartOptions, setTransactionChartOptions] = useState({});
  const [responseTimeChartOptions, setResponseTimeChartOptions] = useState({});
  const [dashboardResponseTimesData, setDashboardResponseTimesData] = useState([]);
  const [dayTansactionData, setDayTansactionData] = useState({});
  const [dayResponseTimeData, setDayResponseTimeData] = useState({});
  const [localData, setLocalData] = useState(
    {
      hourBase:[], dayBase:[], monthBase:[], 
      transData:[], responsetimesData:[]
    }
  );

  const getCurrentDate = (days) => {
    const now = new Date();
    if(days)
      now.setDate(now.getDate() + days);
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  async function fetchData(startDate, endDate) {
    try {
      const fetchedData = await getDashboardData(startDate, endDate);

      const data = fetchedData.datasets[0].data;
      const labels = fetchedData.labels;
      const result = labels.map((label, index) => ({
        OBJECT_TYPE: label,
        N: data[index].toString(),
      }));

      setDashboardData(fetchedData);
      setOriginalDashboardData(fetchedData.datasets[0].data);

      if(objectType.objectType){
        fetchDashboardResponseTimesData(objectType.objectType, startDate, endDate);
      }
    } catch (error) {
      console.error("Error al fetchData: ", error);
    }
  }

  async function fetchDashboardResponseTimesData(
    objectType,
    startDate,
    endDate
  ) {
    try {
      setDashboardResponseTimesData(await getDashboardResponseTimesData(objectType, startDate, endDate));
    } catch (error) {
      console.error("Error al fetchDashboardResponseTimesData:", error);
    }
  }

  useEffect(() => {
    if (getEnterpriseByIDResponse.monitoringService) {
      setEnvironmentURL(getEnterpriseByIDResponse.monitoringService);
      setVersion(getEnterpriseByIDResponse.monitoringVersion);
    }

    if (version && (environmentURL || fullURL)) {
      setUrlMonithorService(fullURL ? fullURL : environmentURL);
      fetchData(
        dateControl.getValues().dateStart,
        dateControl.getValues().dateEnd
      );
    }
  }, [getEnterpriseByIDResponse, version, environmentURL, fullURL]);

  useEffect(() => {
    try {
      if (dashboardData.labels.length == 0) {
        setIsData(false);
      } else {
        setIsData(true);
      }
    } catch (e) {
      console.error(e);
    }
  }, [dashboardData]);

  useEffect(() => {
    if(objectType.objectType){
      fetchDashboardResponseTimesData(objectType.objectType, dateControl.getValues().dateStart, dateControl.getValues().dateEnd);
    }
  }, [objectType]);

  useEffect(() => {
    if (dashboardResponseTimesData.length) {
      const dataTrans = getMonthDayData(dateControl.getValues().dateStart && dateControl.getValues().dateStart.length ? dateControl.getValues().dateStart : "2024/10/01",dateControl.getValues().dateEnd);
      const dataHour = getMonthDayHourData(dateControl.getValues().dateStart && dateControl.getValues().dateStart.length ? dateControl.getValues().dateStart : "2024/10/01",dateControl.getValues().dateEnd);

      const maxCase_ = "Case-" + Math.max(...dashboardResponseTimesData.map(r=>parseInt(r.case_.replace("Case-",""))));
      const dataType = [...new Set(dashboardResponseTimesData.filter(rec=>rec.case_ == maxCase_).map(rec=>rec.dataTypeName))];//.sort((a, b) => a.dataTypeName === b.dataTypeName ? 0 : a.dataTypeName > b.dataTypeName ? 1 : -1);
      const records = [];
      const transRecords = [];
      localData.transData = [];
      localData.responsetimesData = [];
      let total = 0;
      dataType.forEach(rec => {
        const maxTime = Math.max(...dashboardResponseTimesData.filter(r=>r.dataTypeName == rec).map(r=>Number.parseInt(r.averageTime)));
        if(!transRecords.length){
          const search_ = rec.split("-")[0];
          const maxTimeTrans = dashboardResponseTimesData.filter(r=>r.dataTypeName.includes(search_)).map(r=>Number.parseInt(r.transactionQuantity)).reduce((acumulador, valorActual) => acumulador + valorActual, 0);
          const maxTimeTransError = dashboardResponseTimesData.filter(r=>r.dataTypeName.includes(search_)).map(r=>Number.parseInt(r.transactionQuantityError)).reduce((acumulador, valorActual) => acumulador + valorActual, 0);
          total = maxTimeTrans + maxTimeTransError;

          localData.transData.push(getTransactionsGraphicSourceData(search_,false,maxCase_,dataHour.hourData));
          transRecords.push({
            label: `Exitosas: ${new Intl.NumberFormat('es-ES', {style:'decimal'}).format(maxTimeTrans)} (${new Intl.NumberFormat('es-ES', {style:'decimal'}).format((maxTimeTrans/total * 100).toFixed(1))} %)`,
            data: localData.transData[0].dayData,
            backgroundColor: objectType.BG
          });
          localData.transData.push(getErrorTransactionsGraphicSourceData(search_,false,maxCase_,dataHour.hourData));
          transRecords.push({
            label: `Fallidas: ${new Intl.NumberFormat('es-ES', {style:'decimal'}).format(maxTimeTransError)} (${new Intl.NumberFormat('es-ES', {style:'decimal'}).format((maxTimeTransError/total * 100).toFixed(1))} %)`,
            data: localData.transData[1].dayData,
            backgroundColor: "#ff415a"
          });
        }

        localData.responsetimesData.push(getResponseTimesGraphicSourceData(rec,false,maxCase_,dataHour.hourData));
        records.push({
          label: rec + ((maxTime / 60) < 1 ? " (" + maxTime + " seg" : (maxTime / 3600) < 1 ? " (" + (maxTime/60).toFixed(2) + " min" : (maxTime / 1440) < 1 ? " (" + (maxTime/3600).toFixed(2) + " hr" : " (" + (maxTime/1440).toFixed(2) + " d" )+ ")",
          data: localData.responsetimesData[localData.responsetimesData.length - 1].dayData,
          backgroundColor: "#" + Math.floor(Math.random() * 16777215).toString(16)
        });
      });

      setDayTansactionData(
        {
          labels: dataTrans.monthData,
          datasets: transRecords,
          total: total
        }
      );
      setDayResponseTimeData(
        {
          labels: dataTrans.monthData,
          datasets: records
        }
      );
      setIsResponseTimeData(true);
      setIsLoading(false);
    }
  }, [dashboardResponseTimesData]);

  useEffect(() => {
    if(dayTansactionData.labels){
      //var rectangleSet = false;
      setTransactionChartOptions({
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: [objectType.objectType, `# Transacciones al día`, `Total: ${dayTansactionData.total}`],
        },
        legendCallback: function(chart) {
          return "prueba";
        },
        tooltips: {
          mode: 'index',
          intersect: true,
          callbacks: {
            beforeTitle: (tooltipItems, data) => {
              let sum = 0;
              tooltipItems.forEach(function(tooltipItem) {
                sum += tooltipItem.yLabel;
              });
              tooltipItems.forEach(function(tooltipItem) {
                tooltipItem.total = sum;
              });
            },
            label: (tooltipItem, data) => {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';
              var percentage = (tooltipItem.yLabel / tooltipItem.total * 100).toFixed(1);
              return `${label.split(":")[0]}: ${new Intl.NumberFormat('es-ES', {style:'decimal'}).format(tooltipItem.yLabel)} (${percentage} %)`;
            },
            footer: (tooltipItems) => {
              let sum = tooltipItems.length ? tooltipItems[0].total : 0;
              return `Total: ${new Intl.NumberFormat('es-ES', {style:'decimal'}).format(sum)}`;
            }
          }
        },
        plugins: {
          datalabels: {  
            display: false,  
          },
          corsair: {
            dash: [1, 1],
            color: 'black',
            width: 0.5
          },
          zoom: {
						zoom: {
							enabled: true,
							drag: {
                animationDuration: 1000
              },
							mode: 'x',
							speed: 0.05
						}
					}
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Día",
              },
              ticks: {
                display: true,
              },
              stacked: true,
              type: 'time',
              distribution: 'series',
              time: {
                unit: 'day',
              }
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Transacciones",
              },
              ticks: {
                display: true,
                beginAtZero: true,
              },
              stacked: true,
            },
          ],
        },

        /*animation: {
          onComplete: function () {
            if (!rectangleSet) {
              var scale = window.devicePixelRatio;
              var chartTest = chartRefTrans.current.chartInstance;
              var sourceCanvas = chartTest.chart.canvas;
              var copyWidth = chartTest.scales["y-axis-0"].width - 10;
              var copyHeight =
                chartTest.scales["y-axis-0"].height +
                chartTest.scales["y-axis-0"].top +
                10;

              var targetCtx = document
                .getElementById("axis-Test")
                .getContext("2d");

              targetCtx.scale(scale, scale);
              targetCtx.canvas.width = copyWidth * scale;
              targetCtx.canvas.height = copyHeight * scale;

              targetCtx.canvas.style.width = `${copyWidth}px`;
              targetCtx.canvas.style.height = `${copyHeight}px`;
              targetCtx.drawImage(
                sourceCanvas,
                0,
                0,
                copyWidth * scale,
                copyHeight * scale,
                0,
                0,
                copyWidth * scale,
                copyHeight * scale,
              );

              var sourceCtx = sourceCanvas.getContext("2d");

              // Normalize coordinate system to use css pixels.

              sourceCtx.clearRect(0, 0, copyWidth * scale, copyHeight * scale);
              rectangleSet = true;
            }
          },
          onProgress: function () {
            if (rectangleSet === true) {
              var chartTest = chartRefTrans.current.chartInstance;
              var copyWidth = chartTest.scales["y-axis-0"].width;
              var copyHeight =
                chartTest.scales["y-axis-0"].height +
                chartTest.scales["y-axis-0"].top +
                10;

              var sourceCtx = chartTest.chart.canvas.getContext("2d");
              sourceCtx.clearRect(0, 0, copyWidth, copyHeight);
            }
          }
        }*/
      });
    }
  }, [dayTansactionData]);

  useEffect(() => {
    if(dayResponseTimeData.labels){
      setResponseTimeChartOptions({
        responsive: true,
        title: {
          display: true,
          text: [objectType.objectType,"Tiempo de respuesta promedio"],
        },
        tooltips: {
          mode: 'index',
          intersect: true,
          callbacks: {
            label: (tooltipItem, data) => {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';
              return ((tooltipItem.yLabel / 60) < 1 ?  tooltipItem.yLabel + " seg" : (tooltipItem.yLabel / 3600) < 1 ?  (tooltipItem.yLabel/60).toFixed(2) + " min" : (tooltipItem.yLabel / 1440) < 1 ?  (tooltipItem.yLabel/3600).toFixed(2) + " hr" :  (tooltipItem.yLabel/1440).toFixed(2) + " d" ) + "  : " + label.split("(")[0];
            },
            footer: (tooltipItems) => {
              let sum = 0;
            
              tooltipItems.forEach(function(tooltipItem) {
                sum += tooltipItem.yLabel;
              });
              return 'Total: ' + ((sum / 60) < 1 ?  sum + " seg" : (sum / 3600) < 1 ?  (sum/60).toFixed(2) + " min" : (sum / 1440) < 1 ?  (sum/3600).toFixed(2) + " hr" :  (sum/1440).toFixed(2) + " d" );
            }
          }
        },
        plugins: {
          datalabels: {  
            display: false,  
          },
          corsair: {
            dash: [1, 1],
            color: 'black',
            width: 0.5
          },
          zoom: {
						zoom: {
							enabled: true,
							drag: {
                animationDuration: 1000
              },
							mode: 'x',
							speed: 0.05
						}
					}
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Tiempo (seg)",
              },
              ticks: {
                display: true,
              },
              stacked: true,
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Día-Hora",
              },
              ticks: {
                display: true,
              },
              stacked: true,
              type: 'time',
              distribution: 'series',
              time: {
                unit: 'day',
                /*displayFormats: {
                  day: 'MMM D hA'
                }*/
              }
            },
          ],
        },
      });
    }
  }, [dayResponseTimeData]);

  const responseTimeActions = [
    {
      name: 'Meses',
      onClick: function(chart) {
        chart.data.labels = localData.monthBase;
        chart.data.datasets.forEach((dataset, index)=>{
          dataset.data = localData.responsetimesData[index].monthData;
        });
        chart.options.scales.xAxes[0].time.unit = "month";
        chart.update();
      }
    },
    {
      name: 'Días',
      onClick: function(chart) {
        chart.data.labels = localData.dayBase;
        chart.data.datasets.forEach((dataset, index)=>{
          dataset.data = localData.responsetimesData[index].dayData;
        });
        chart.options.scales.xAxes[0].time.unit = "day";
        chart.update();
      },
    },
    {
      name: 'Horas',
      onClick: function(chart) {
        chart.data.labels = localData.hourBase;
        chart.data.datasets.forEach((dataset, index)=>{
          dataset.data = localData.responsetimesData[index].hourData;
        });
        chart.options.scales.xAxes[0].time.unit = "hour";
        chart.options.scales.xAxes[0].time.displayFormats = {
          hour: 'MMM D hA'
        }
        chart.update();
      }
    },
    {
      name: 'Restaurar',
      onClick: function(chart) {
        chart.resetZoom();
      }
    }
  ];
  const tansactionActions = [
    {
      name: 'Meses',
      onClick: function(chart) {
        chart.data.labels = localData.monthBase;
        chart.data.datasets.forEach((dataset, index)=>{
          dataset.data = localData.transData[index].monthData;
        });
        chart.options.scales.xAxes[0].time.unit = "month";
        chart.update();
      }
    },
    {
      name: 'Días',
      onClick: function(chart) {
        chart.data.labels = localData.dayBase;
        chart.data.datasets.forEach((dataset, index)=>{
          dataset.data = localData.transData[index].dayData;
        });
        chart.options.scales.xAxes[0].time.unit = "day";
        chart.update();
      },
    },
    {
      name: 'Horas',
      onClick: function(chart) {
        chart.data.labels = localData.hourBase;
        chart.data.datasets.forEach((dataset, index)=>{
          dataset.data = localData.transData[index].hourData;
        });
        chart.options.scales.xAxes[0].time.unit = "hour";
        chart.options.scales.xAxes[0].time.displayFormats = {
          hour: 'MMM D hA'
        }
        chart.update();
      }
    },
    {
      name: 'Restaurar',
      onClick: function(chart) {
        chart.resetZoom();
      }
    }
  ];

  const {
    control: dateControl,
    handleSubmit: handleDateSubmit,
    errors: dateErrors,
  } = useForm({
    defaultValues: {
      dateStart: getCurrentDate(-7),
      dateEnd: getCurrentDate(),
    },
    shouldUnregister: false,
    mode: "onChange",
  });

  const integrationChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    plugins: {
      type: "doughnutWithTextCenter",
      datalabels: {
        color: "#fff",
        display: "auto",
        display: (context) => {
          const value = context.dataset.data[context.dataIndex];
          const sum = originalDashboardData.reduce((a, b) => a + b, 0);
          return (value / sum) * 100;
        },
        formatter: (value, context) => {
          if (!originalDashboardData) return "";
          let sum = originalDashboardData.reduce((a, b) => a + b, 0);
          let percentage = (value / sum) * 100;
          return percentage < 0.01 ? "< 0.01%" : percentage.toFixed(2) + "%";
        },
        anchor: "center",
        align: "center",
        offset: 1,
        clip: true,
        backgroundColor: (context) => {
          let sum = originalDashboardData.reduce((a, b) => a + b, 0);
          let percentage =
            (context.dataset.data[context.dataIndex] / sum) * 100;
          return "rgba(0, 0, 0, 0.3)";
        },
      },
      totalText: {
        text: new Intl.NumberFormat('es-ES', {style:'decimal'}).format(originalDashboardData?.reduce((a, b) => a + b, 0) ?? 0),
        color: "#6D6E71",
        fontSize: 28,
      },
    },
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
      },
    },
    title: {
      display: true,
      text: "# Transacciones por Integración",
    },
    onClick: (e, element) => {
      if (element.length > 0) {
        let ind = element[0]._index;
        console.log(dashboardData);
        setObjectType({objectType:dashboardData.labels[ind],BG:dashboardData.datasets[0].backgroundColor[ind]});
        setIsLoading(true);
      }
    },
  };

  const getDummyData = (quantity, value, vertical) => {
    const data = [];
    for (let index = 0; index < quantity - 1; index++) {
      if(vertical)
        data.push({ y: index, x: Math.floor(Math.random() * value) });
      else
        data.push({ x: index, y: Math.floor(Math.random() * value) });
    }
    return data;
  };

  const getResponseTimesGraphicSourceData = (metricName, vertical, maxCase_, base) => {
    const data = {hourData:[], dayData:[], monthData:[]};
    if(base?.length){
      base.forEach(recBase => {
        const record = {};
        record.hourLabel = recBase;
        record.dayLabel = recBase.substring(0,10);
        record.monthLabel = recBase.substring(0,7) + "-01";
        record.quantityDay = 0;
        record.quantityMonth = 0;
        if(vertical){
          record.y = recBase;
          record.x = 0;
          record.xDay = 0;
          record.xMonth = 0;
        } else{
          record.x = recBase;
          record.y = 0;
          record.yDay = 0;
          record.yMonth = 0;
        }
        dashboardResponseTimesData.filter(
          rec => rec.dataTypeName.split("-")[1] == metricName.split("-")[1]
          // && rec.case_ == maxCase_
          && rec.transactionDate.substring(0,13) == recBase
          && rec.averageTime > 0
          ).
          reduce((p, c) => {
            let idx = p[0].indexOf(c.transactionDate);
            if (idx > -1) {
              p[1][idx].averageTime += c.averageTime
            } else {
              p[0].push(c.transactionDate);
              p[1].push(c);
            }
            return p;
          }, [[],[]])[1].
          forEach(rec => {
          if(vertical){
            record.x = Number.parseFloat(rec.averageTime);
            record.xDay = Number.parseFloat(rec.averageTime);
            record.xMonth = Number.parseFloat(rec.averageTime);
          }
          else{
            record.y = Number.parseFloat(rec.averageTime);
            record.yDay = Number.parseFloat(rec.averageTime);
            record.yMonth = Number.parseFloat(rec.averageTime);
          }
        });
        //if(record.y)
        data.hourData.push(record);
      });
    }

    data.dayData = data.hourData.reduce((p, c) => {
      let idx = p[0].indexOf(c.dayLabel);
      if (idx > -1) {
        p[1][idx].quantityDay++;
        if(vertical)
          p[1][idx].xDay += c.xDay
        else
          p[1][idx].yDay += c.yDay
      } else {
        p[0].push(c.dayLabel);
        p[1].push(c);
      }
      return p;
    }, [[],[]])[1].map(rec => { return vertical ? {x: (rec.xDay/rec.quantityDay).toFixed(2), y: rec.dayLabel}:{y: (rec.yDay/rec.quantityDay).toFixed(2), x: rec.dayLabel};});

    data.monthData = data.hourData.reduce((p, c) => {
      let idx = p[0].indexOf(c.monthLabel);
      if (idx > -1) {
        p[1][idx].quantityMonth++;
        if(vertical)
          p[1][idx].xMonth += c.xMonth
        else
          p[1][idx].yMonth += c.yMonth
      } else {
        p[0].push(c.monthLabel);
        p[1].push(c);
      }          
      return p;
    }, [[],[]])[1].map(rec => { return vertical ? {x: (rec.xMonth/rec.quantityMonth).toFixed(2), y: rec.monthLabel}:{y: (rec.yMonth/rec.quantityMonth).toFixed(2), x: rec.monthLabel};});

    return data;
  };

  const getTransactionsGraphicSourceData = (metricName, vertical, maxCase_, base) => {
    const data = {hourData:[], dayData:[], monthData:[]};
    if(base?.length){
      base.forEach(recBase => {
        const record = {};
        record.hourLabel = recBase;
        record.dayLabel = recBase.substring(0,10);
        record.monthLabel = recBase.substring(0,7) + "-01";
        if(vertical){
          record.y = recBase;
          record.x = 0;
          record.xDay = 0;
          record.xMonth = 0;
        } else{
          record.x = recBase;
          record.y = 0;
          record.yDay = 0;
          record.yMonth = 0;
        }
        dashboardResponseTimesData.filter(
          rec => rec.dataTypeName.split("-")[0] == metricName 
          //&& rec.case_ == maxCase_
          && rec.transactionDate.substring(0,13) == recBase
          ).
          reduce((p, c) => {
            let idx = p[0].indexOf(c.transactionDate);
            if (idx > -1) {
              p[1][idx].transactionQuantity += c.transactionQuantity
            } else {
              p[0].push(c.transactionDate);
              p[1].push(c);
            }
            return p;
          }, [[],[]])[1].
          forEach(rec => {
            if(vertical){
              record.x = rec.transactionQuantity;
              record.xDay = rec.transactionQuantity;
              record.xMonth = rec.transactionQuantity;
            }
            else{
              record.y = rec.transactionQuantity;
              record.yDay = rec.transactionQuantity;
              record.yMonth = rec.transactionQuantity;
            }
          });
        //if(record.y)
        data.hourData.push(record);
      });
    }

    data.dayData = data.hourData.reduce((p, c) => {
      let idx = p[0].indexOf(c.dayLabel);
      if (idx > -1) {
        if(vertical)
          p[1][idx].xDay += c.xDay
        else
          p[1][idx].yDay += c.yDay
      } else {
        p[0].push(c.dayLabel);
        p[1].push(c);
      }
      return p;
    }, [[],[]])[1].map(rec => { return vertical ? {x: rec.xDay, y: rec.dayLabel}:{y: rec.yDay, x: rec.dayLabel};});

    data.monthData = data.hourData.reduce((p, c) => {
      let idx = p[0].indexOf(c.monthLabel);
      if (idx > -1) {
        if(vertical)
          p[1][idx].xMonth += c.xMonth
        else
          p[1][idx].yMonth += c.yMonth
      } else {
        p[0].push(c.monthLabel);
        p[1].push(c);
      }          
      return p;
    }, [[],[]])[1].map(rec => { return vertical ? {x: rec.xMonth, y: rec.monthLabel}:{y: rec.yMonth, x: rec.monthLabel};});

    return data;
  };

  const getErrorTransactionsGraphicSourceData = (metricName, vertical, maxCase_, base) => {
    const data = {hourData:[], dayData:[], monthData:[]};
    if(base?.length){
      base.forEach(recBase => {
        const record = {};
        record.hourLabel = recBase;
        record.dayLabel = recBase.substring(0,10);
        record.monthLabel = recBase.substring(0,7) + "-01";
        if(vertical){
          record.y = recBase;
          record.x = 0;
          record.xDay = 0;
          record.xMonth = 0;
        } else{
          record.x = recBase;
          record.y = 0;
          record.yDay = 0;
          record.yMonth = 0;
        }
        dashboardResponseTimesData.filter(
          rec => rec.dataTypeName.split("-")[0] == metricName 
          //&& rec.case_ == maxCase_
          && rec.transactionDate.substring(0,13) == recBase
          ).
          reduce((p, c) => {
            let idx = p[0].indexOf(c.transactionDate);
            if (idx > -1) {
              p[1][idx].transactionQuantityError += c.transactionQuantityError
            } else {
              p[0].push(c.transactionDate);
              p[1].push(c);
            }
            return p;
          }, [[],[]])[1].
          forEach(rec => {
            if(vertical){
              record.x = rec.transactionQuantityError;
              record.xDay = rec.transactionQuantityError;
              record.xMonth = rec.transactionQuantityError;
            }
            else{
              record.y = rec.transactionQuantityError;
              record.yDay = rec.transactionQuantityError;
              record.yMonth = rec.transactionQuantityError;
            }
          });
        //if(record.y)
        data.hourData.push(record);
      });
    }

    data.dayData = data.hourData.reduce((p, c) => {
      let idx = p[0].indexOf(c.dayLabel);
      if (idx > -1) {
        if(vertical)
          p[1][idx].xDay += c.xDay
        else
          p[1][idx].yDay += c.yDay
      } else {
        p[0].push(c.dayLabel);
        p[1].push(c);
      }
      return p;
    }, [[],[]])[1].map(rec => { return vertical ? {x: rec.xDay, y: rec.dayLabel}:{y: rec.yDay, x: rec.dayLabel};});

    data.monthData = data.hourData.reduce((p, c) => {
      let idx = p[0].indexOf(c.monthLabel);
      if (idx > -1) {
        if(vertical)
          p[1][idx].xMonth += c.xMonth
        else
          p[1][idx].yMonth += c.yMonth
      } else {
        p[0].push(c.monthLabel);
        p[1].push(c);
      }          
      return p;
    }, [[],[]])[1].map(rec => { return vertical ? {x: rec.xMonth, y: rec.monthLabel}:{y: rec.yMonth, x: rec.monthLabel};});

    return data;
  };

  const getMonthData = (init, end) => {
    let fechaActual = new Date(init);  
    let fechaFinal = new Date(end);  
    const data = [];
    let transQ = 0;
    while (fechaActual <= fechaFinal) {  
        data.push(fechaActual.toISOString().substring(0, 10));
        fechaActual.setMonth(fechaActual.getMonth() + 1); // Avanza un mes
        transQ++;
    }
    localData.monthBase = data;
    return {monthData: data, transQuantity: transQ, monthDataLight: [data[0], fechaFinal]};
  };

  const getMonthDayData = (init, end) => {
    let fechaActual = new Date(init);  
    let fechaFinal = new Date(end);  
    const data = [];
    let transQ = 0;
    while (fechaActual <= fechaFinal) {  
        data.push(fechaActual.toISOString().substring(0, 10));
        fechaActual.setDate(fechaActual.getDate() + 1); // Avanza un día
        transQ++;
    }
    getMonthData(init, end);
    localData.dayBase = data;
    return {monthData: data, transQuantity: transQ, monthDataLight: [data[0], fechaFinal]};
  };

  const getMonthDayHourData = (init, end) => {
    let fechaActual = new Date(init);  
    let fechaFinal = new Date(end);  
    const data = [];
    let transQ = 0;
    fechaFinal.setDate(fechaFinal.getDate() + 1)
    while (fechaActual < fechaFinal) {
        data.push(fechaActual.toISOString().substring(0,13));
        fechaActual.setHours(fechaActual.getHours() + 1); // Avanza una hora
        transQ++;
    }
    localData.hourBase = data;
    return {hourData: data, transQuantity: transQ, hourDataLight: [data[0], fechaFinal]};
  };

  const plugin = {
    id: 'corsair',
    afterInit: (chart) => {
      chart.corsair = {
        x: 0,
        y: 0
      }
    },
    afterEvent: (chart, evt) => {
      const {
        chartArea: {
          top,
          bottom,
          left,
          right
        }
      } = chart;
      const {
        x,
        y
      } = evt;
      if (x < left || x > right || y < top || y > bottom) {
        chart.corsair = {
          x,
          y,
          draw: false
        }
        chart.draw();
        return;
      }

      chart.corsair = {
        x,
        y,
        draw: true
      }

      chart.draw();
    },
    afterDatasetsDraw: (chart, _, opts) => {
      const {
        ctx,
        chartArea: {
          top,
          bottom,
          left,
          right
        }
      } = chart;
      const {
        x,
        y,
        draw
      } = chart.corsair;

      if (!draw) {
        return;
      }

      ctx.lineWidth = opts.width || 0;
      ctx.setLineDash(opts.dash || []);
      ctx.strokeStyle = opts.color || 'black'

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, bottom);
      ctx.lineTo(x, top);
      ctx.moveTo(left, y);
      ctx.lineTo(right, y);
      ctx.stroke();
      ctx.restore();
    }
  };

  ChartJS.plugins.register(
    {
      id: "totalText",
      afterDatasetsDraw: function (chart, _) {
        if (chart.options.plugins.type == "doughnutWithTextCenter") {
          const ctx = chart.ctx;
          const width = chart.width;
          const height = chart.height;
          const fontSize = chart.options.plugins.totalText.fontSize;
          ctx.font = fontSize + "px Arial";
          ctx.fillStyle = chart.options.plugins.totalText.color;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          const text = chart.options.plugins.totalText.text;
          const textX = Math.round(width / 2);
          const textY = Math.round(height / 1.75);
          ctx.fillText(text, textX, textY);
        }
      },
    }
  );

  ChartJS.plugins.register(zoomPlugin);

  const handleDataWithDate = () => {
    fetchData(dateControl.getValues().dateStart, dateControl.getValues().dateEnd);
    if(isResponseTimeData)
      setIsLoading(true);
  };

  const dataTMP = [
    {initTransation_:"2024_10_28_17",dataTypeName_:"01-INVOICES_COUPA",minTime:"0",maxTime:"0",AverageTime:"0",Transaction_:"36",Year_:"2024",Month_:"10",Day_:"28",Hour_:"17",dataTypeName_Max:"01-INVOICES_COUPA-0"}
      ];

  return (
    <>
      <Grid item container spacing={1} sm={6} xs={12}>
        <Grid item md={6} sm={6} xs={12}>
          <ControlledInputRoundedForm
            id="dateStart"
            name="dateStart"
            label="Fecha inicio"
            control={dateControl}
            fullWidth
            type="date"
            style={{ marginLeft: "10px", marginTop: "10px" }}
            error={dateErrors.dateStart}
            shrink={true}
            helperText={dateErrors.dateStart?.message}
          ></ControlledInputRoundedForm>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <ControlledInputRoundedForm
            id="dateEnd"
            name="dateEnd"
            label="Fecha fin"
            control={dateControl}
            fullWidth
            type="date"
            shrink={true}
            style={{ marginLeft: "10px", marginTop: "10px" }}
            error={dateErrors.dateEnd}
            helperText={dateErrors.dateEnd?.message}
          ></ControlledInputRoundedForm>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          sm={4}
          xs={12}
          style={{
            paddingLeft: "22px",
            paddingTop: "10px",
          }}
        ></Grid>
      </Grid>
      <Grid
        item
        container
        sm={3}
        xs={12}
        style={{
          paddingLeft: "20px",
          paddingTop: "15px",
        }}
      >
        <IconEspecialButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          size={"large"}
          startIcon={<Search />}
          onClick={() => {
            handleDataWithDate();
          }}
        >
          Buscar
        </IconEspecialButton>
      </Grid>
      {isData && (
        <Grid container spacing={1} sm={12} xs={12} style={{paddingLeft:"30px", paddingTop:"20px"}}>
          <Grid item sm={6} xs={12} /*style={{minWidth:"390px"}}*/>
            <div className="doughnut">
              {dashboardData.labels.length > 0 && (
                <ul className="custom-legend custom-legend-left">
                  {dashboardData.labels.map((label, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setObjectType({objectType:dashboardData.labels[index],BG:dashboardData.datasets[0].backgroundColor[index]});
                        setIsLoading(true);
                      }}
                      // className={
                      //   selectedDashboardIndexes.includes(index)
                      //     ? "selected"
                      //     : ""
                      // }
                    >
                      <span
                        style={{
                          backgroundColor: dashboardData.datasets[0].backgroundColor[index],
                        }}
                      ></span>
                      {label}
                    </li>
                  ))}
                </ul>
              )}
              <div className="canva">
                <Doughnut
                  data={dashboardData}
                  options={{ ...integrationChartOptions }}
                  width={269}
                  height={300}
                  //ref={chartRefInt}
                />
              </div>
            </div>
          </Grid>

          {isResponseTimeData && (
          <Grid item sm={6} xs={12}>
            <div className="transactionData">
              <div className="transactionDataArea">
                <div className="chart-actions">
                  {chartRefTrans.current && tansactionActions.map((action)=> (
                    <a className="chart-action" onClick={()=>action.onClick(chartRefTrans.current.chartInstance.chart)}>{action.name}</a>
                  ))}
                </div>
                <div id="transactionDataArea2">
                  <Bar
                    data={dayTansactionData}
                    legend={{position:"top"/*, display: false*/}}
                    options={{ ...transactionChartOptions }}
                    width={1200}
                    height={500}
                    ref={chartRefTrans}
                    plugins={[plugin]}
                  />
                </div>
              </div>
              {/* <canvas id="axis-Test" height="300" width="0"></canvas> */}
            </div>
          </Grid>
          )}

          {isResponseTimeData && (
          <Grid item spacing={0} sm={12} xs={12}>
            <div className="responseTimeData">
              <div className="responseTimeDataArea">
                <div className="chart-actions">
                  {chartRefResp.current && responseTimeActions.map((action)=> (
                    <a className="chart-action" onClick={()=>action.onClick(chartRefResp.current.chartInstance.chart)}>{action.name}</a>
                  ))}
                </div>
                <div id="responseTimeDataArea2">
                  <Bar
                    data={dayResponseTimeData}
                    legend={{position:"bottom"}}
                    options={{ ...responseTimeChartOptions }}
                    width={1200}
                    height={500}
                    ref={chartRefResp}
                    plugins={[plugin]}
                  />
                </div>
              </div>
            </div>
          </Grid>
          )}
          
        </Grid>
        
      )}
      <FullLoader open={isLoading} viewLoader={true} />
    </>
  );
}
